<template>
  <div>
    <div class="page-body">
      <title-bar title="用户授权">
        <el-button type="default" @click="$router.back()" icon="fa fa-angle-left" :loading="loading">
          返回
        </el-button>
      </title-bar>
      <el-transfer
          filterable
          :filter-method="filterMethod"
          filter-placeholder="请输入角色名称"
          v-model="value"
          :data="roles"
          :titles="['角色列表','已授权角色']"
          :props="{key:'roleId', label:'roleName',disabled:'disabled'}"
          class="role-transfer"
      >
      </el-transfer>
      <div class="button-container" style="margin-top: 20px;">
        <el-button type="primary" @click="save()" icon="fa fa-save" :loading="loading">
          保存授权
        </el-button>
        <el-button type="default" @click="$router.back()" icon="fa fa-angle-left" :loading="loading">
          取消
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar";
import TablePagination from "@/components/base/TablePagination";
import CodeSelect from "@/components/base/CodeSelect";
import Store from "@/utils/Store";

export default {
  components: {CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      userId: "",
      roles: [],
      value: [],
    }
  },
  created() {
    this.userId = this.$route.query.userId;
    this.loading = true;
    this.postForm("/role/list",{valid: true},true).then(dr => {
      this.roles = dr.rows;
    }).finally(dr => {
      this.loading = false;
    });
    this.postForm("/role-member/userRoles", {userId: this.userId}, true).then(dr => {
      this.value = [];
      dr.rows.forEach(role => {
        this.value.push(role.roleId);
      });
    });
  },
  methods: {
    filterMethod(query, item) {
      return item.roleName.indexOf(query) != -1;
    },
    //授权
    save() {
      this.loading = true;
      this.postForm("/role-member/saveUserRoles",{
        userId: this.userId,
        roles: this.value
      },true).finally(dr => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.role-transfer{
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>
